import { AxiosRequestConfig } from 'axios';
import urlUtil from '../utils/url';
import { ApiResult } from './api-service/api-result.model';
import Axios from 'axios';
import { APIType } from './configuration';

export enum HTTP_HEADER_KEY {
	ContentType = 'Content-Type',
	ActivePodId = 'Active-Pod-Id',
	Authorization = 'Authorization'
  }

export interface IGatewayOptions {
	url: string;
	data?: any;
	method?: string;
	responseType?: ResponseType;
	headers?: any
}

const JSON_CONTENT_TYPE = 'application/json';

export abstract class BaseHTTPClient {
	public static async sendToGateway<T>(opts: IGatewayOptions): Promise<ApiResult<T>> {
		if (opts.method === undefined) {
			opts.method = APIType.POST;
		}
		let axiosResult = null;
		let result = null;
		opts.url = urlUtil.transformUrl(opts.url);
		const axiosOpts = {
			headers: {
				'Content-Type': JSON_CONTENT_TYPE,
				'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
				...opts?.headers,
			}
		};

		try {
			switch (opts.method) {
				case APIType.POST:
					axiosResult = await Axios.post(opts.url, opts.data, axiosOpts);
					break;
				case APIType.GET:
					axiosResult = await Axios.get(opts.url, { params: opts.data });
					break;
				case APIType.DELETE:
					axiosResult = await Axios.delete(opts.url, { data: opts.data });
					break;
				case 'UPLOAD':
					axiosResult = await Axios.post(opts.url, opts.data);
					break
				default:
					break;
			}
			result = new ApiResult(axiosResult?.data, '');
		} catch (error: any) {
			BaseHTTPClient.logError(error, opts.data);
			result = new ApiResult(error?.response!, error?.message);
			throw error;
		}
		return result;
	}

	protected static async get<T>(
		url: string,
		contextHeader: any = {}
	): Promise<ApiResult<T>> {
		let result: ApiResult<T>;
		const axiosOpts: AxiosRequestConfig = {
			headers: {
				[HTTP_HEADER_KEY.ContentType]: 'application/json',
				...contextHeader
			}
		};
		try {
			const axiosResult = await Axios.get(url, axiosOpts);
			result = new ApiResult<T>(axiosResult?.data, []);
		} catch (error) {
			result = new ApiResult<any>(null, error);
		}
		return result;
	}

	protected static async post<T>(
		url: string,
		data: any,
		contextHeader: any = {}
	): Promise<ApiResult<T>> {
		let result: ApiResult<T>;
		const axiosOpts: AxiosRequestConfig = {
			headers: {
				[HTTP_HEADER_KEY.ContentType]: 'application/json',
				...contextHeader
			}
		};
		try {
			const axiosResult = await Axios.post(url, data, axiosOpts);
			result = new ApiResult<T>(axiosResult?.data, []);
		} catch (error) {
			result = new ApiResult<any>(null, error);
		}

		return result;
	}

	protected static async put<T>(
		url: string,
		data: any,
		contextHeader: any = {}
	): Promise<ApiResult<T>> {
		let result: ApiResult<T>;
		const axiosOpts: AxiosRequestConfig = {
			headers: {
				[HTTP_HEADER_KEY.ContentType]: 'application/json',
				...contextHeader
			}
		};
		try {
			const axiosResult = await Axios.put(url, data, axiosOpts);
			result = new ApiResult<T>(axiosResult?.data, []);
		} catch (error) {
			result = new ApiResult<any>(null, error);
		}
		return result;
	}

	protected static async delete<T>(
		url: string,
		data: any,
		contextHeader: any = {}
	): Promise<ApiResult<T>> {
		let result: ApiResult<T>;
		const axiosOpts: AxiosRequestConfig = {
			headers: {
				[HTTP_HEADER_KEY.ContentType]: 'application/json',
				...contextHeader
			},
			data: data,
			timeout: 300000
		};
		try {
			const axiosResult = await Axios.delete(url, axiosOpts);
			result = new ApiResult<T>(axiosResult?.data, '');
		} catch (error) {
			result = new ApiResult<any>(null, error);
		}
		return result;
	}

	private static logError(error: any, apiConfig: any | undefined) {
		if (!process.env.NODE_ENV || (process.env.NODE_ENV === 'development' && apiConfig)) {
			console.log('API REQUEST FAILED', apiConfig);
			// dev code
		} else {
			// production code
		}

	}
}
