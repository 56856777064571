
import './App.scss';

import React, { FC } from 'react';
import { MotifButton } from '@ey-xd/motif-react';
import AppLayout from './layout/AppLayout';
import configureStore from '../src/redux/core/store.config';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MsalProvider } from "@azure/msal-react";
import { Configuration,  PublicClientApplication } from "@azure/msal-browser";

const configuration: Configuration = {
    auth: {
        clientId: "44069b9c-3bfd-4577-8f93-b6424be88c39",
        redirectUri: "/",
        authority: "https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c"
    },
    cache: {
        cacheLocation: 'localStorage'
    }
};

const instance = new PublicClientApplication(configuration);
instance.initialize()


const App: FC = () => (
    <MsalProvider instance={instance}>
        <Provider store={configureStore()}>
            <BrowserRouter>
                <AppLayout />
            </BrowserRouter>
        </Provider>
    </MsalProvider>
);

export default App;
