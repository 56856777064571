import { Dispatch } from 'redux'
import { ReportLibraryService } from '../../../services/reportLibrary.service';


export const getReportLibrary: any = (params: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: 'GET_REPORT_LIBRARY_LOADING' });
        const res = await ReportLibraryService.getReportLibrary(params);
        dispatch({ type: 'GET_REPORT_LIBRARY_SUCCESS', payload: res.value });
    } catch (error) {
        dispatch({ type: 'GET_REPORT_LIBRARY_ERROR' });
    }
}

export const saveReport: any = (params: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: 'SAVE_REPORT_LOADING' });
        const res = await ReportLibraryService.saveReport(params);
        dispatch({ type: 'SAVE_REPORT_SUCCESS', payload: res.value });
    } catch (error) {
        dispatch({ type: 'SAVE_REPORT_ERROR' });
        throw new Error('Failed to save report');
    }
}

export const udpateReportLibrary: any = (item: any) => (dispatch: Dispatch) => {
    dispatch({ type: 'UPDATE_REPORT_LIBRARY', payload: item});
}

export const deleteReport: any = (id: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: 'DELETE_REPORT_LOADING' });
        const res = await ReportLibraryService.deleteReport({ "record_id": id });
        if(`${res.value}`.includes('success')) {
            dispatch({ type: 'DELETE_REPORT_SUCCESS', payload: id });
        } else {
            throw new Error('Failed to delete report');
        }
    } catch(e) {
        dispatch({ type: 'DELETE_REPORT_ERROR' });
        throw new Error('Failed to delete report');
    }
}

export const downloadReport: any = (params: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: 'DOWNLOAD_REPORT_LOADING' });
        const res = await ReportLibraryService.downloadReport(params);
        dispatch({ type: 'DOWNLOAD_REPORT_SUCCESS', payload: res.value });
    } catch (error) {
        dispatch({ type: 'DOWNLOAD_REPORT_ERROR' });
        throw new Error('Failed to download report');
    }
}
